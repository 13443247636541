




















import {
    Component,
    Vue,
    Watch
} from 'vue-property-decorator';
 

import {
    Auth
} from '@/store/auth'
import {
    Core
} from '@/store/core'
import {
    User
} from '@/store/user'
import {
    Iit
} from '@/store/iit'
import _ from "lodash"

@Component({
    components: {
         
    },
})
export default class Home extends Vue {

    private user!: any;
    private yearData!: any;
    private agency: any = null
    private response:any = false;

    public async created() {
        await this.run();
  
        this.response = true
    }

    private async run() {
        this.user = await User.getUser();
        // this.agency = await Core.getHttp(`/api/ita/v1/agency/${this.$route.query.agency}/`)
        // this.yearData = await Core.getHttp(`/api/iit/v2/year/${this.$route.query.year}/`)
        
    }
 

}
